import React from "react";
import Link from "next/link";
import { ComponentProps } from "react";
import { LinkNoPrefetch } from "./LinkNoPrefetch";
import { optimizeURL } from "../../utils/url";

interface LinkProps extends ComponentProps<"a"> {
  href?: string;
  children: React.ReactNode;
  target: string;
  /**
   * Creates a link with no prefetching abilities... which Next Links per default have
   */
  disablePrefetch?: boolean;
  /**
   * Creates a link which triggers prefetching only on hover... Next Links prefetch per default as soon as link is visible
   */
  onlyPrefetchOnHover?: boolean;
  className?: string;
}

export const CustomLink = ({
  children,
  href,
  disablePrefetch = false,
  onlyPrefetchOnHover = false,
  className,
  ...props
}: LinkProps) => {
  const rel = props.target === "_blank" ? "noopener noreferrer" : undefined;

  if (!href || href.length === 0) {
    // Return nothing if link is not set
    return null;
  }

  const extraprops = {
    ...(rel && { rel }),
    ...props,
  };

  return disablePrefetch ? (
    // eslint-disable-next-line react/forbid-elements
    <LinkNoPrefetch
      {...extraprops}
      href={optimizeURL(href)!}
      className={className}
    >
      {children}
    </LinkNoPrefetch>
  ) : !onlyPrefetchOnHover ? (
    // eslint-disable-next-line react/forbid-elements
    <Link
      href={optimizeURL(href)!}
      prefetch={false}
      className={className}
      {...extraprops}
    >
      {children}
    </Link>
  ) : (
    // eslint-disable-next-line react/forbid-elements
    <Link href={optimizeURL(href)!} className={className} {...extraprops}>
      {children}
    </Link>
  );
};
