import Image from "next/image";
import Link from "next/link";
import { ImageType, isAvailable, OptionsType } from "../../../utils/baseTypes";
import { CustomLink } from "../../Global/CustomLink";

interface LogoProps {
  options: OptionsType;
}

export const Logo = ({ options }: LogoProps) => {
  const logo = options.acf.logos[0].logo;
  const link = options.acf.logos[0].link;

  let imageJSX;
  if (logo.mime_type == "image/svg+xml") {
    imageJSX = (
      <object
        data={logo.url as string}
        width={230}
        height={12.49}
        title={logo.title}
      />
    );
  } else {
    imageJSX = (
      <Image
        src={logo.url as string}
        alt={logo.alt}
        data-title={logo.title}
        width={230}
        height={12.49}
      />
    );
  }

  console.log(link);
  return (
    <div className="logo">
      {isAvailable<ImageType>(logo) && (
        <CustomLink
          href={link.url}
          target={link.target}
          aria-label={link.title !== "" ? link.title : "Zur Startseite"}
        >
          {imageJSX}
        </CustomLink>
      )}
    </div>
  );
};
